<template>
  <div v-if="article != null" class="magazin__detail" @click="handleClick">

    <!-- IMAGE -->
    <div class="magazin__detail__image">
        <figure v-if="main_image.image">
            <img  max-width="100%" :src="'https://app.sportlerplus.com' + main_image.image.meta.download_url" />
            <figcaption v-if="main_image.credits" class="caption--license">{{ main_image.credits }}</figcaption>
            <figcaption v-if="main_image.caption" class="caption--description">{{ main_image.caption }}</figcaption>
        </figure>
    </div>

    <!-- INTRO -->
    <div class="magazin__detail__meta">
      <div class="magazin__detail__meta__date">{{ articleReadTime() }}</div>
      <div v-if="article.series_subtitle" class="magazin__detail__meta__teil">{{ article.series_subtitle }}</div>
    </div>
    <h2>{{ article.title }}</h2>
    <p class="magazin__detail__intro">{{ article.intro }}</p>

    <div class="magazin__detail__podcast" v-if="article.podcast_file">
        <audio controls controlsList="nodownload">
            <source :src="'https://media.sportlerplus.com/podcasts/' + article.podcast_file" type="audio/mpeg">
        </audio>
    </div>

    <!-- SUMMARY -->
    <!-- <ul class="magazin__detail__summary">
      <small>Inhalt</small>
      <li><a href="#was-sind-makronahrstoffe">Was sind Makronährstoffe?</a></li>
      <li><a href="#">Kohlenhydrate, Protein, Fett: Welches Verhältnis sinnvoll ist</a></li>
    </ul> -->

    <!-- CONTENT -->
    <template v-for="b in article.body_parts">
      <div v-if="b.type == 'cta_button'" v-bind:key="'cta_' + b.id" class="magazin__detail__content magazin__detail__content__cta" v-html="b.value"></div>
      <div v-else-if="b.type == 'table_of_contents'" v-bind:key="'toc_' + b.id" class="magazin__detail__content magazin__detail__toc" v-html="b.value"></div>
      <div v-else-if="b.type == 'bibliography'" v-bind:key="'bib_' + b.id"></div>
      <div v-else v-bind:key="b.id" class="magazin__detail__content" v-html="b.value"></div>
    </template>

    <div v-if="article.vgwort_token"><img :src="'https://vg08.met.vgwort.de/na/' + article.vgwort_token" width="1" height="1" alt=""></div>

    <div id="endOfArticle"></div>

    <!-- AUTHOR -->
    <router-link :to="'/your-pro-detail/' + articleAuthor.id" class="author" v-if="articleAuthor">
      <div class="author__image">
        <img :src="'https://app.sportlerplus.com/scaled/200/' + articleAuthor.avatar_image_url">
      </div>
      <div class="author__content">
        <div class="author__name">
          {{ articleAuthor.name }}
        </div>
        <div class="author__description">
          {{ articleAuthor.role }}・{{ articleAuthor.subtitle }}
        </div>
      </div>
    </router-link>

    <!-- Quellenverweise -->
    <template v-if="articleBibliography">
        <!-- Quellenverweise -->
        <a @click="showBibliography" style="text-decoration: none;" class="magazin__detail__source">
        Quellenverweise
        </a>

        <VModal name="bibliography" @closed="hideBibliography" class="large" width="100%" height="auto" :scrollable="true">
            <div class="vm-header">
                <div class="vm-header-left">
                </div>
                <div class="vm-header-center">
                    Quellenverweise
                </div>
                <div class="vm-header-right">
                    <img src="@/assets/ic_nav_close.svg" alt="" @click="hideBibliography">
                </div>
            </div>
            <div class="vm-content">
                <div class="magazin__detail__source__content" v-html="articleBibliography.value">
                </div>
            </div>
        </VModal>
    </template>

    <!-- MORE/PARTS --->

    <div v-if="isArticleSeries" class="magazin__detail__more text-center">
      
      <small>Du willst mehr zu diesem Thema wissen?</small>
      
      <div v-for="(related_article, i) in furtherArticles" :key="i" class="magazin__detail__more__item">
        <router-link v-if="related_article.id != article.id" :to="'/articles/' + related_article.id">
          <div class="magazin__detail__more__item__image">
            <img v-if="related_article.square_tile_image_url" :src="'https://app.sportlerplus.com' + related_article.square_tile_image_url" alt="" />
            <img v-else :src="'https://app.sportlerplus.com/scaled/800/' + related_article.main_image_url" alt="" />
          </div>
          <div class="magazin__detail__more__item__text">
            <div class="magazin__detail__more__item__badge">
              {{ related_article.series_subtitle }}
            </div>
            <div class="magazin__detail__more__item__title">
              {{ related_article.title }}
            </div>
          </div>
        </router-link>
      </div>

    </div>

  </div>
  <div v-else>
    <div v-show="errorLoadingArticle">Fehler beim Laden des Artikels.</div>
  </div>

</template>

<script>
import axios from 'axios';
import {analytics} from '@/firebase/index.js';

export default {
    name: 'ArticleDetail',
    metaInfo() {
        if (this.article == null) { return {meta: []} }
		return { 
            title: this.article.title,
            description: this.article.intro,
			meta: [
				{
                    vmid: "og:title",
					property: "og:title",
					content: this.article.title
				},
                {
                    vmid: "og:description", 
					property: "og:description",
					content: this.article.intro
				},
				{
                    vmid: "og:site_name",
					property: "og:site_name",
					content: "SportlerPlus"
				},
				{
                    vmid: "og:type",
					property: "og:type",
					content: "article"
				},
				{
                    vmid: "og:image",
					property: 'og:image', 
					content: 'https://app.sportlerplus.com/scaled/800/' + this.article.main_image_url
				}
			]
		}
	},
    data() {
        return {
            article: null,
            furtherArticles: null,
            isFavourite: false,
            isArticleSeries: false,
            errorLoadingArticle: false,
            articleAuthor: null,
            scrolledToBottom: false,
            readArticleList: [],
        }
    },
    methods: {
        showBibliography() {
            this.$modal.show("bibliography");
        },
        hideBibliography() {
            this.$modal.hide("bibliography");
        },
        articleReadTime() {
            if (this.readArticleList.indexOf(this.article.id) != -1) {
                return "Bereits gelesen";
            }
            return this.article.readtime + " Min.";
        },
        init(articleId) {
            analytics.logEvent('view_article', {
                article_id: articleId,
            });
            this.readArticleList = this.$store.getters.getReadArticleList;
            this.scrolledToBottom = false;
            let apiUrl = this.$store.getters.getApiUrl;
            axios
            .get(apiUrl + 'pages/' + articleId + "/")
            .then(response => {
                this.article = response.data;
                this.$emit('setCurrentArticle', this.article);
                this.isArticleSeries = !(this.article.meta.parent.title == "Magazin");
                let parentId = this.article.meta.parent.id;
                if (this.isArticleSeries) {
                    let furtherArticlesUrl = "pages/?type=blog.BlogPage&child_of=" + parentId + "&fields=title,series_subtitle,main_image_url,square_tile_image_url&order=go_live_at";
                    axios.get(apiUrl + furtherArticlesUrl)
                    .then(response => {
                        this.furtherArticles = response.data.items;
                    });
                }
                if (this.article.coach) {
                    this.$store.dispatch('fetchCoaches').then( () => {
                        let coaches = this.$store.getters.getCoaches;
                        if (coaches) {
                            this.articleAuthor = coaches.find(c => c.id == this.article.coach.id);
                        }
                    });
                }
                this.$nextTick( () => {
                    var toc_container = document.querySelector(".magazin__detail__toc");
                    if (toc_container) {
                        [...toc_container.getElementsByTagName("ol")].forEach(el => el.classList.add("magazin__detail__summary"));
                    }
                    else {
                        // fallback to old behaviour, add class to all OL elements
                        [...document.getElementsByTagName("ol")].forEach(el => el.classList.add("magazin__detail__summary"));
                    }
                    this.detectScrollingToEnd();
                });
            })
            .catch(error => {
                console.log(error);
                // set article object to only contain id so the removeLike button can work
                this.$emit('setCurrentArticle', {id: articleId});
                this.errorLoadingArticle = true;
            });
        },
        detectScrollingToEnd() {
            window.addEventListener("scroll", () => {
                var elementTarget = document.getElementById("endOfArticle");
                if (!elementTarget) { return; }
                if ((window.scrollY + window.innerHeight) > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
                    if (!this.scrolledToBottom) {
                        this.scrolledToBottom = true;
                        this.$store.commit('markArticleAsRead', this.article.id);
                    }
                }
            });
        },
        isNumeric(str) {
            if (typeof str != "string") return false // we only process strings!  
            return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
        },
        handleClick(e) {
            if (e.target.href) {
                let hashValue = e.target.hash;
                if (hashValue && hashValue != "") {
                    let targetElement = document.getElementById(hashValue.substr(1));
                    if (targetElement) {
                        targetElement.scrollIntoView({behavior: 'smooth'});
                        e.preventDefault();
                    }
                    else {
                        console.log("error - link target not found: " + hashValue);
                    }
                }
            }
        }
    },
    components: {

    },
    computed: {
        main_image() {
            if (this.article && this.article.gallery_images) {
                return this.article.gallery_images[0];
            }
            return {};
        },
        articleBibliography() {
            if (this.article && this.article.body_parts) {
                let bibliography = this.article.body_parts.find(el => el.type == "bibliography");
                return bibliography;
            }
            else { return null; }
        }
    },
    mounted() {
        let articleId = this.$route.params.id;
        if (this.isNumeric(articleId)) {
            this.init(articleId);
        }
        else {
            let apiUrl = this.$store.getters.getApiUrl;
            axios
                .get(apiUrl + 'pages?slug=' + articleId)
                .then(response => {
                    let searchResult = response.data;
                    if (searchResult.items && searchResult.items.length > 0) {
                        articleId = searchResult.items[0].id;
                        this.init(articleId);
                    }
                });
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.params.id);
        next();
    }
}
</script>

<style lang="scss">
  .magazin__detail {
    &__image {
      width: calc(100% + 40px);
      margin: -24px -20px 0;
      overflow: hidden;
      position: relative;

      &__caption {
        position: absolute;
        bottom: 8px;
        left: 8px;
        background: rgba($colorBlack, .32);
        color: rgba($colorWhite, .64);
        font-size: 11px;
        font-weight: 600;
        padding: 2px 6px;
        border-radius: 2px;
      }

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }

      figure {
        margin-bottom: 0 !important;
      }
    }

    &__meta {
      display: flex;
      align-items: center;
      margin: 27px 0 19px;

      &__date {
        font-size: 11px;
        font-weight: 600;
        letter-spacing: -0.16px;
        color: rgba($colorBlack, .48);
        margin-right: auto;
      }

      &__teil {
        display: inline-flex;
        font-size: 11px;
        line-height: 11px;
        font-weight: 700;
        letter-spacing: -0.13px;
        text-transform: uppercase;
        padding: 4px 5px 3px 6px;
        border-radius: 2px;
        color: $colorWhite;
        background: $colorPrimary;
      }
    }

    &__podcast {
        display: flex;
        align-items: center;
        margin: 27px 0 19px;

        audio {
            width: 100%;
        }
    }

    p {
      font-size: 17px;
      line-height: 28px;
      letter-spacing: -0.4px;
    }

    h2 {
      font-weight: 700;
      margin-bottom: 18px;
    }

    h3 {
      position: relative;
      padding-left: 16px;
      margin: 29px 0;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        height: calc(100% - 15px);
        width: 4px;
        background: $colorPrimary;
      }
    }

    h4 {
      font-size: 20px;
      margin: 19px 0 11px;
    }

    h5 {
      font-size: 17px;
      margin: 19px 0 11px;
    }

    ul, ol {
      padding-left: 18px;

      li {
        font-size: 17px;
        line-height: 28px;
        letter-spacing: -0.4px;
        font-weight: 500;
        color: rgba($colorBlack, .64);

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    &__intro {
      font-size: 17px;
      letter-spacing: -0.4px;
      font-weight: 600;
      color: $colorBlack;
      opacity: 1;
      margin-bottom: 40px;
    }

    &__summary {
      width: calc(100% + 40px);
      margin: 0 -20px;
      padding: 0 !important;
      list-style-type: none;
      border-bottom: 1px solid rgba($colorBlack, .08);
      margin-bottom: 32px;

      @media screen and (min-width: 768px) {
        width: 100%;
        margin: 0;
        padding: 0 !important;
      }

      small {
        font-size: 11px;
        line-height: 40px;
        text-transform: uppercase;
        margin-left: 20px;

        @media screen and (min-width: 768px) {
          margin-left: 0;
        }
      }

      li {
        position: relative;
        font-size: 17px;
        /* font-weight: 700; */
        letter-spacing: -0.23px;
        box-shadow: 0 -1px 0 0 rgba($colorBlack, .08);
        padding: 12px 20px 12px 46px;
        line-height: 1.5!important;
        margin-bottom: 0!important;

        &:before {
          content: url('../assets/ic_magazin_arrow.svg');
          position: absolute;
          top: 16px;
          left: 20px;
          width: 18px;
          height: 18px;
        }

        a {
          color: $colorBlack;
          text-decoration: none;
        }
      }
    }

    figure {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      margin-bottom: 16px;

      .caption {

        &--license {
          background: rgba($colorBlack, .32);
          color: rgba($colorWhite, .64);
          font-size: 11px;
          font-weight: 600;
          padding: 3px 6px;
          border-radius: 2px;
          margin: -30px 8px 8px auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: calc(100% - 16px);
        }

        &--description {
          background: rgba($colorBlack, .08);
          color: rgba($colorBlack, .64);
          font-size: 11px;
          padding: 5px 8px;
          border-radius: 0 0 4px 4px;
          width: 100%;
        }
      }
    }

    &__content {

      img {
        width: 100%;
        height: auto;
        box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
        border-radius: 4px;
      }
      
      a {
        color: $colorPrimary!important;
        font-weight: 700;
      }

      &__cta {
        a {
            text-decoration: none;
        }
        button {
            margin-top: 10px;
            margin-bottom: 10px;
        }
      }
    }

    &__more {
      margin: 40px 0 16px;

      &__item {
        display: flex;
        background: $colorWhite;
        box-shadow: 0 8px 16px 0 rgba($colorBlack, .04);
        border-radius: 4px;
        overflow: hidden;
        margin: 16px 0;
        cursor: pointer;

        a { display: flex; text-decoration: none; }

        &__image {
          width: 100%;
          max-width: 80px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 12px 8px 12px 16px;
        }

        &__badge {
          display: inline-flex;
          color: $colorWhite;
          background: $colorPrimary;
          font-size: 11px;
          line-height: 11px;
          font-weight: 700;
          letter-spacing: -0.13px;
          text-transform: uppercase;
          padding: 4px 5px 3px 6px;
          border-radius: 2px;
          margin-bottom: 8px;
        }

        &__title {
          font-size: 17px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: -0.25px;
          text-align: left;
          color: $colorBlack;
        }
      }
    }

    &__source {
        display: flex;
        justify-content: center;
        width: calc(100% + 40px);
        margin: 0 -20px;
        padding: 11px 0 13px;
        font-size: 11px;
        font-weight: 500;
        color: rgba($colorBlack, .48) !important;
        background: rgba($colorBlack, .04);
        transition: all .2s ease-in-out;

        &:hover {
            background: rgba($colorBlack, .08);
        }

        &__content {

            ol {
                li {
                    font-size: 14px !important;
                    line-height: 20px !important;
                    padding: 2px 0;

                    a {
                        color: rgba($colorBlack, .64);
                    }
                }
            }
        }
    }
  }

  .author {
    display: flex;
    align-items: center;
    box-shadow: inset 0 1px 0 0 #E6E7EB, inset 0 -1px 0 0 #E6E7EB;
    width: calc(100% + 40px);
    margin: 40px -20px 0;
    padding: 8px 20px 8px 12px;
    text-decoration: none;

    &__image {
      display: flex;
      margin-right: 8px;

      img {
        height: 56px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__name {
      font-size: 14px;
      font-weight: 700;
      color: $colorBlack;
      letter-spacing: -0.38px;
    }

    &__description {
      font-size: 11px;
      font-weight: 600;
      color: rgba($colorBlack, .48);
      letter-spacing: -0.16px;
      text-transform: uppercase;
    }
  }
</style>